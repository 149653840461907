<template>
    <section>
        <p class="text-black f-500 f-20 mb-2">
            Edades por genero
        </p>
        <div class="row mx-0">
            <div v-if="verGrafica" class="col-12 px-0">
                <echart :options="edadesGenero" />
            </div>
            <div v-else class="col-12 px-0 text-center">
                <img class="obj-cover" src="/img/no-imagen/no-grafica.png" />
            </div>
        </div>        
    </section>
</template>
<script>
import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);
export default {
    props: {
        data: {
            type: Array,
            default: [],
        },
    },
    data(){
        return {
            verGrafica: false,
            edadesGenero: {
                legend: {
                    show: true,
                    data: [
                        { name: 'Hombres', icon: 'square', textStyle: '#000000', },
                        { name: 'Mujeres', icon: 'square', textStyle: '#000000', },
                        { name: 'Otros', icon: 'square', textStyle: '#000000', }
                    ]
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: (params) => {
                        let hombres = params[0]
                        let mujeres = params[1]
                        let otros = params[2]
                        return `${params[0].axisValue}: <br />
                        ${hombres.marker} ${hombres.value} Hombres <br />
                        ${mujeres.marker} ${mujeres.value} Mujeres <br />
                        ${otros.marker} ${otros.value} Otros <br />
                        ` 
                    },
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#DFE4E8"
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#DFE4E8"
                        }
                    },
                    axisLabel: {
                        color: "#637381"
                    }
                },
                yAxis: {
                    type: 'value',
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#DFE4E8"
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: "#DFE4E8"
                        }
                    },
                    axisLabel: {
                        color: "#637381"
                    }
                },
                series: [
                    {   
                        name: 'Hombres',
                        data: [],
                        type: 'bar',
                        stack: 'genero',
                        itemStyle: {
                            color: '#80DCFF'
                        }
                    },
                    {   
                        name: 'Mujeres',
                        data: [],
                        type: 'bar',
                        stack: 'genero',
                        itemStyle: {
                            color: '#FFB2C2'
                        }
                    },
                    {   
                        name: 'Otros',
                        data: [],
                        type: 'bar',
                        stack: 'genero',
                        itemStyle: {
                            color: '#B2B2FF'
                        }
                    }
                ]
            }
        }
    },

    watch:{
        data(){
            this.mapearData();
        },
    },

    mounted(){
        this.mapearData();
    },

    methods: {
        mapearData(){
            this.verGrafica = false;

            let data = this.data;
            let xAxis = [];
            let hombres = [];
            let mujeres = [];
            let otros = [];
            let contadore = data.length;

            if(parseInt(contadore) == 0)return;

            for(let i = 0; i < contadore; i++){
                let titulo = `${data[i].desde} a ${data[i].hasta}`

                if(parseInt(data[i].hasta) === 18){
                    titulo = '-18';
                }
                if(parseInt(data[i].desde) === 61){
                    titulo = '+60';
                }
                
                xAxis = [...xAxis, titulo];
                hombres = [...hombres, data[i].hombres];
                mujeres = [...mujeres, data[i].mujeres];
                otros = [...otros, data[i].otros];
            }

            this.edadesGenero.xAxis.data = xAxis;
            this.edadesGenero.series[0].data = hombres;
            this.edadesGenero.series[1].data = mujeres;
            this.edadesGenero.series[2].data = otros;

            this.verGrafica = true;
        },
    },
}
</script>
<style lang="scss" scoped>
    .img-sindatos{
        position: absolute;
        z-index: 3;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0%;
        left: 0px;
        width: 100%;
        height: 100%;
        img{
            // border-radius: 50%;
            /* max-height: 100%; */
            box-shadow: 0px 3px 6px #00000029;
            max-width: 50%;
            height: 50%;
        }
        .mensaje{
            color: #fff;
            text-shadow: 0 2px 3px #000000;
            position: absolute;
            top: 58%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    @media (min-width : 320px) and (max-width : 480px) {
        .img-sindatos img{
            max-width: 100% !important;
        }
    }
</style>